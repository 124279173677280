jQuery(document).ready(function($) {
	'use strict';

	// FAQ page:

	$('.faq ').each( function (index, value)  {
		var title = $(this).find('h2').outerHeight();
		var excerpt = $(this).find('.excerpt').outerHeight();

		if ( excerpt > title ) {
			$(this).height(excerpt);
			$(this).find('h2').height(excerpt - 60);
			console.log(index);
			console.log("excerpt: " + excerpt);
		} else {
			$(this).height(title);
			$(this).find('.excerpt').height(title - 60);
			console.log(index);
			console.log("title: " + title);
			console.log("excerpt: " + excerpt);
		}
	});


	$('.faq-wrapper').masonry({
		// options
		columnWidth: '.faq',
		gutter: '.gutter-sizer',
		itemSelector: '.faq'
	});

	$('iframe[src*="youtube.com"]:not(.no-full)').each(function() {
		$(this).wrap( "<div class='yt-video-container'></div>" );
		$(this).removeAttr('width');
		$(this).removeAttr('height');
	});

});
