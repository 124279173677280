'use strict';

var materialURL = '/results/material/';
var addressURL = '/results/address/';
var Recollect;

jQuery(document).ready(function($) {

	if ( $( ".rCmaterial-search" ).length ) {
		Recollect.Search.Material.Bind({
		    node_id: '.rCmaterial-search',
		    area: 'MMBC',
		    url: materialURL
		});
	}

	if ( $( ".rCaddress-search" ).length ) {
		Recollect.Search.Address.Bind({
		    node_id: '.rCaddress-search',
		    area: 'MMBC',
		    url: addressURL
		});
	}

	if ( $( "#rCaddress-search-header" ).length ) {
		Recollect.Search.Address.Bind({
		    node_id: '#rCaddress-search-header',
		    area: 'MMBC',
		    url: addressURL
		});
	}
	
	var scope = '.rCsearch.header';
	$(scope + ' .rCtabs a').click(function(e) {
		e.preventDefault();
		if (!$(this).hasClass('active')) {
			$(scope + ' .rCtabs a').removeClass('active');
			$(this).addClass('active');

			var src = $(this).attr('href');
			$(scope + ' .rCform').css('display', 'none');
			$(src).css('display', 'block');
		}
	});

	var link = $('.recollect-search-widget .rCtoolbar a.help-link');

	link.click(function() {
		if ( $(this).attr('data-page').length ) {
			window.location = "" + materialURL + '#!rc-page=' + $(this).attr('data-page');
		}
	});

});